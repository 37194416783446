body {
  margin: 40px auto;
  max-width: 700px;
  line-height: 1.5;
  font-size: 18px;
  color: #333;
  padding: 0 10px;
}

h1,
h2,
h3 {
  line-height: 1.2;
}

ul {
  padding-left: 25px;
}

ul.dotted {
  padding: 0;
  list-style-type: none;
}

ul.dotted li:not(:last-child) {
  border-bottom: 1px dashed #666;
  margin-bottom: .2em;
  padding-bottom: .2em;
}

svg.inline-icon {
  width: 1.5em;
  height: 1.5em;
  vertical-align: text-bottom;
}

a:link, a:hover, a:active, a:visited {
  color: #55e;
  text-decoration-skip-ink: auto;
}